<template>
  <div>
    <div class="container">
      <h1
        style="
          display: flex;
          justify-content: center;
          width: 100%;
          font-size: xx-large;
          font-weight: bold;
        "
      >
        DIJ Releases
      </h1>
      <article
        v-for="(release, index) in releases"
        :key="index"
        class="release"
      >
        <header class="release-header">
          <strong>Version: {{ release.version }}</strong>
          <span class="release-date">Date: {{ release.date }}</span>
        </header>
        <section
          v-for="(items, sectionName) in computedSectionData(release)"
          :key="sectionName"
          :class="`section ${sectionName}`"
        >
          <div class="section-content">
            <div class="section-title">
              <img
                class="section-icon"
                alt="'text'"
                :src="getSectionIcon(sectionName)"
              />
              <h2>{{ formatSectionName(sectionName) }}</h2>
            </div>

            <ul>
              <li v-for="(item, idx) in items" :key="`${sectionName}-${idx}`">
                <span v-if="isObject(item)" class="element-object">
                  <span>{{ item.jiraId }} | {{ item.title }} </span>
                  <a class="incident-display" :href="item.incidentUrl">{{
                    item.incidentNumber
                  }}</a>
                </span>
                <span v-else>{{ formatSectionItem(item) }}</span>
              </li>
            </ul>
          </div>
        </section>
        <div v-if="release.tag" class="tag">Tag: {{ release.tag }}</div>
      </article>
    </div>
  </div>
</template>

<script>
import versionData from '../../../version_data.json';
import VersionForm from './VersionForm';

// feat/fix: DIJ-XXX (INCXXXXXX) DONE: Non functional title

export default {
  components: {
    VersionForm,
  },
  data() {
    return {
      releases: versionData,
      isComponentModalActive: false,
      newVersion: {
        version: '',
        date: new Date(),
        section: '',
        details: '',
        tag: '',
      },
    };
  },
  methods: {
    isObject(item) {
      return typeof item === 'object';
    },
    formatSectionItem(item) {
      if (typeof item === 'object') {
        return;
      }

      return item;
    },
    getSectionIcon(sectionName) {
      return `/img/icons/${sectionName}.png`;
    },
    normalizeData(data) {
      return Array.isArray(data) ? data : [data];
    },
    formatSectionName(name) {
      return name.charAt(0).toUpperCase() + name.slice(1);
    },
  },
  computed: {
    computedSectionData() {
      const sections = [
        'fixes',
        'features',
        'removals',
        'improvements',
        'hotfix',
        'updates',
      ];
      return (release) =>
        sections.reduce((acc, section) => {
          if (release[section]) {
            acc[section] = this.normalizeData(release[section]);
          }
          return acc;
        }, {});
    },
  },
};
</script>

<style scoped>
:root {
  --primary-color: #3273dc; /* Primary blue */
  --background-color: #f5f5f5; /* Light grey background */
  --font-color: #363636; /* Main text color */
  --secondary-font-color: #4a4a4a; /* Slightly lighter text */
  --border-color: #dbdbdb; /* Border color */
  --header-background: #f0f0f0; /* Light grey header */
}

body {
  font-family: 'Segoe UI', 'Arial', sans-serif;
  margin: 0;
  padding: 20px;
  background: var(--background-color);
}

.section-icon {
  width: 25px;
}

.section-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.section-content ul {
  width: 100%;
}

.section-content ul li {
  display: flex;
}

.element-object {
  width: 100%;
  display: flex;
}

.incident-display {
  font-weight: bold;
  margin-left: auto;
  font-style: italic;
  color: gray !important;
}

.section-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: start;
  align-items: center;
}

.container {
  max-width: 960px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.release {
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 20px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding: 10px;
}

/* Desktop specific styles */
@media (min-width: 1024px) {
  .release:hover {
    transform: scale(1.3); /* Slightly scale up the card */
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.25);
    font-size: 1.05em; /* Slightly increase the font size */
  }
}

.release-header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: var(--header-background);
  color: var(--font-color);
}

.release-date {
  font-size: 0.9em;
  color: var(--secondary-font-color);
}

.section {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border-top: 1px solid var(--border-color);

  border-radius: 5px;
  margin-top: 5px;
}

.section h2 {
  font-size: 1.1em;
  color: var(--primary-color);
  font-weight: bold;
}

.section ul {
  list-style: none;
  padding-left: 20px;
}

.section li::before {
  content: '→';
  margin-right: 8px;
  color: var(--primary-color);
}

.tag {
  padding: 10px 20px;
  background-color: var(--header-background);
  color: var(--font-color);
}

.b-button {
  transition: background-color 0.3s ease;
}

.b-button:hover {
  background-color: darken(var(--primary-color), 10%);
}

.fixes {
  background-color: #d4edda; /* Light Green */
}

.features {
  background-color: #d1ecf1; /* Light Blue */
}

.removals {
  background-color: #f8d7da; /* Light Red */
}

.improvements {
  background-color: #fff3cd; /* Light Yellow */
}

.hotfix {
  background-color: #feebc8; /* Light Orange */
}

.updates {
  background-color: #e2e3f3; /* Light Purple */
}
</style>
