import { render, staticRenderFns } from "./TaskHeader.vue?vue&type=template&id=4adf03ac&scoped=true"
import script from "./TaskHeader.vue?vue&type=script&lang=js"
export * from "./TaskHeader.vue?vue&type=script&lang=js"
import style0 from "./TaskHeader.vue?vue&type=style&index=0&id=4adf03ac&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4adf03ac",
  null
  
)

export default component.exports