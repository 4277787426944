const mutations = {
  TOGGLE_EXCLUDE_ITEMS: (state) => {
    state.enableExcludeItems = !state.enableExcludeItems;
  },
  ITEM_TO_EXCLUDE: (state, { id, value }) => {
    if (value === true) {
      if (!state.excludedItems.includes(id)) {
        state.excludedItems.push(id);
      }
    } else if (state.excludedItems.includes(id)) {
      state.excludedItems = state.excludedItems.filter((l) => l !== id);
    }
  },
  TOGGLE_INTERNAL_REPORT: (state, value) => {
    state.isInternalReport = value;
  },

  SET_REPORT_VIEW: (state, data) => {
    state.reportView = data;
  },
};

export default mutations;
