<template>
  <div class="task-header-container">
    <div class="breadcrumbs-container">
      <slot name="breadcrumbs"></slot>
    </div>
    <div class="container-buttons">
      <slot name="buttons"></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.task-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 100%;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.4); /* Adjust shadow properties as needed */
}

.container-buttons {
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 5px;
}

.breadcrumbs-container {
  display: flex;
  align-items: center;
}

.button {
  min-width: 110px;
  width: auto;
}
</style>
