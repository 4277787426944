<template>
  <div v-if="!isLoading">
    <TaskView
      ref="taskViewComponent"
      type="inspector"
      @onSave="onSignOff"
      @onShowRepairAssessment="repairAssessmentVisible = true"
      @onShowManageMTE="isMteVisible = true"
      @accuScanImport="accuScanImport"
    >
      <div v-if="isDiagram">
        <DiagramView
          ref="diagramView"
          :show-list="true"
          :show-toolbar="false"
          tool="inspector"
        />
      </div>
      <div v-if="isInstruction">
        <InstructionView
          ref="instructionView"
          :task="currentTask"
          viewType="inspector"
        />
      </div>
    </TaskView>
    <repair-assessment-detail-dialog
      tool="report"
      :readOnly="false"
      v-if="repairAssessmentVisible"
      @close="hideRepairAssessment"
    />
    <mte-management v-if="isMteVisible" @close="hideMte" />
    <SignOffModal :is-active="showSignOff" @onClose="onCloseSignOff" />
  </div>
  <div v-else>
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import DiagramView from '../../../components/Shared/DiagramView';
import InstructionView from '../../../components/Shared/InstructionView';
import RepairAssessmentDetailDialog from '../../../components/layout/RepairAssessmentDetailDialog';
import MteManagement from '../../../components/layout/MteManagement';
import SignOffModal from './SignOffModal';

import TaskView from '../../../components/Shared/TaskView';
import TaskViewMixin from '../../../mixins/Shared/TaskViewMixin';

export default {
  components: {
    DiagramView,
    InstructionView,
    TaskView,
    RepairAssessmentDetailDialog,
    MteManagement,
    SignOffModal,
  },
  mixins: [TaskViewMixin],
  computed: {
    // `isDiagram` computed property is inherited from TaskViewMixin
    // `currentTask` computed property is inherited from TaskViewMixin
  },
  data() {
    return {
      repairAssessmentVisible: false,
      isMteVisible: false,
      showSignOff: false,
    };
  },
  methods: {
    // `onSave` method is inherited from TaskViewMixin
    accuScanImport(data) {
      this.$refs.diagramView.onAccuScanImport(data);
    },

    onSignOff() {
      this.showSignOff = true;
    },

    onCloseSignOff() {
      this.showSignOff = false;
      this.onSave();
    },

    hideRepairAssessment() {
      this.repairAssessmentVisible = false;
    },
    hideMte() {
      this.isMteVisible = false;
    },
  },
};
</script>
