<template>
  <div>
    <template v-if="!isLoading">
      <ReportHeader
        :onDownload="onDownloadReport"
        :disabledDownload="disabledDownload"
      />
      <div id="container-main">
        <repair-assessment-detail-dialog
          tool="report"
          :readOnly="false"
          v-if="repairAssessmentVisible"
          @close="hideRepairAssessment"
          class="dij-ra-header-modal"
        />
        <DeviationReportModal
          :active="deviationReportVisible"
          @close="hideDeviationReport"
        />
        <section>
          <div class="columns">
            <div class="column">
              <b-field label="Company Name">
                <b-input
                  v-on:blur="onUpdateJobReport('companyInfoText')"
                  v-model="companyInfoText"
                  size="is-medium"
                  placeholder="Enter Company Information"
                ></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Customer">
                <b-input
                  v-model="customerInfoText"
                  v-on:blur="onUpdateJobReport('customerInfoText')"
                  size="is-medium"
                  placeholder="Enter Customer Information"
                ></b-input>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <div class="columns">
                <div class="column">
                  <b-field>
                    <template slot="label">
                      <LabelInput
                        label="Unit Serial"
                        itemToExcludeId="serial"
                      />
                    </template>
                    <b-input
                      v-model="serialNumber"
                      v-on:blur="onUpdateJobReport('serialNumber')"
                      size="is-medium"
                      placeholder="Enter Serial Info"
                    ></b-input>
                  </b-field>
                </div>

                <div class="column">
                  <b-field>
                    <template slot="label">
                      <LabelInput label="Unit Model" itemToExcludeId="model" />
                    </template>
                    <b-input
                      v-model="unitModel"
                      size="is-medium"
                      readonly
                    ></b-input>
                  </b-field>
                </div>
              </div>
            </div>

            <div class="column">
              <div class="columns">
                <div class="column">
                  <b-field>
                    <template slot="label">
                      <LabelInput
                        label="Project Number"
                        itemToExcludeId="project"
                      />
                    </template>
                    <b-input
                      v-model="projectInfoText"
                      v-on:blur="onUpdateJobReport('projectInfoText')"
                      size="is-medium"
                      placeholder="Enter Project Number"
                    ></b-input>
                  </b-field>
                </div>

                <div class="column">
                  <b-field>
                    <template slot="label">
                      <LabelInput label="Sales Order" itemToExcludeId="sales" />
                    </template>
                    <b-input
                      v-model="salesInfoText"
                      v-on:blur="onUpdateJobReport('salesInfoText')"
                      size="is-medium"
                      placeholder="Enter Sales Order"
                    ></b-input>
                  </b-field>
                </div>

                <div class="column">
                  <b-field>
                    <template slot="label">
                      <LabelInput
                        label="Sales Force Opportunity Number"
                        itemToExcludeId="salesForce"
                      />
                    </template>
                    <b-input
                      v-model="salesForceNumber"
                      v-on:blur="onUpdateJobReport('salesForceNumber')"
                      size="is-medium"
                      placeholder="Enter Sales Force Opportunity Number"
                    ></b-input>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
          <b-field>
            <template #label>
              <div id="lable-summary">
                <template>
                  <LabelInput
                    label="Executive Summary"
                    itemToExcludeId="summaryText"
                  />
                </template>
                <div @click="onQuestionSummaryClick">
                  <b-icon id="icon-summary" icon="help-circle" />
                </div>
              </div>
              <div id="executive-summary-example" v-if="showSummaryExemple">
                Sample Text: <br />
                Siemens provided technical services on behalf of Duke Energy
                Carolinas LLC to inspect and advise repair recommendations for
                an EMM32A3N rotor assembly. The rotor assembly had been sent to
                the Siemens repair facility in Charlotte, North Carolina for an
                incoming inspection.
              </div>
            </template>
            <b-input
              type="textarea"
              v-model="summaryText"
              v-on:blur="onUpdateJobReport('summaryText')"
              placeholder="Please enter a brief work order summary here..."
            ></b-input>
          </b-field>
          <div class="columns">
            <div class="column">
              <b-field>
                <template slot="label">
                  <LabelInput
                    label="Revision Description"
                    itemToExcludeId="revisionDescription"
                  />
                </template>
                <b-input
                  v-model="revisionDescription"
                  size="is-medium"
                  placeholder="Enter Revision Description"
                  type="text"
                  required
                  ref="refRevisionDescription"
                  id="refRevisionDescription"
                  v-on:blur="onUpdateJobReport('revisionDescription')"
                ></b-input>
              </b-field>
            </div>

            <div class="column">
              <div class="columns">
                <div class="column">
                  <b-field>
                    <template slot="label">
                      <LabelInput label="CSM" itemToExcludeId="csmField" />
                    </template>
                    <b-input
                      v-model="csmField"
                      size="is-medium"
                      placeholder="Enter CSM"
                      type="text"
                      required
                      ref="refcsmField"
                      id="refcsmField"
                      v-on:blur="onUpdateJobReport('csmField')"
                    ></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field>
                    <template slot="label">
                      <LabelInput
                        label="Repair Engineer"
                        itemToExcludeId="repairEngineer"
                      />
                    </template>
                    <b-input
                      v-model="repairEngineer"
                      size="is-medium"
                      placeholder="Enter Repair Engineer"
                      type="text"
                      required
                      ref="refRepairEngineer"
                      id="refRepairEngineer"
                      v-on:blur="onUpdateJobReport('repairEngineer')"
                    ></b-input>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="deviation-report-container" v-if="isInternalReport">
          <b-button
            type="is-primary"
            icon-left="plus"
            @click="addDeviationReport"
            v-if="!job.deviationReport"
            >Deviation Report</b-button
          >
          <div v-if="job.deviationReport" class="deviation-report-edit">
            <span>Deviation Report</span>
            <b-button
              @click="showDeviationReport"
              size="is-small"
              style="margin-left: 10px"
              >Edit</b-button
            >
          </div>

          <DeviationReport
            :key="deviationKey"
            v-if="job.deviationReport"
            :isReadonly="true"
            ref="deviationReport"
          />
        </div>

        <div v-if="job.repairAssessment" class="repair-assessment-container">
          <div>
            <div>
              <span>Repair Assessment</span>
              <b-button @click="showRepairAssessment" size="is-small"
                >Edit</b-button
              >
            </div>
          </div>
          <b-tabs type="is-boxed" :expanded="false" v-model="activeTab">
            <b-tab-item
              label="Major Components"
              icon="engine"
              class="b-tab-item-scroll"
            >
              <repair-assessment-detail
                ref="repairAssessment"
                v-model="filteredMajorRepairAssessment"
                :read-only="true"
                :tool="'editor'"
              />
            </b-tab-item>
            <b-tab-item
              label="Minor Components"
              icon="screw-flat-top"
              class="b-tab-item-scroll"
            >
              <repair-assessment-detail
                ref="repairAssessment"
                v-model="filteredMinorRepairAssessment"
                :read-only="true"
                :tool="'editor'"
                :isMinorComponents="true" /></b-tab-item
          ></b-tabs>
        </div>
        <template v-for="task in tasksInOrder">
          <Instruction
            :key="task._id"
            v-if="task.taskType === 'instruction'"
            :taskProp="task"
            :id="task._id"
          />
          <Diagram
            :key="task._id"
            v-if="task.taskType === 'diagram'"
            :taskProp="task"
            :isExclude="excludedItems.includes(task._id)"
          />
        </template>
        <ExternalData />
      </div>
    </template>
    <b-loading
      :is-full-page="true"
      :active="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import { v4 } from 'uuid';
import ReportHeader from './ReportHeader';
import Instruction from './tasks/Instruction';
import Diagram from './tasks/Diagram';
import LabelInput from './components/LabelInput';
import ExternalData from './components/ExternalData';
import DIJVueConfigurations from '../../helpers/DIJVueConfigurations';
import RepairAssessmentDetail from '../../components/layout/RepairAssessmentDetail';
import RepairAssessmentDetailDialog from '../../components/layout/RepairAssessmentDetailDialog';
import DeviationReport from './components/DeviationReport';
import DeviationReportModal from './components/DeviationReportModal';

import ReportDataParserMixin from '../../mixins/Report/ReportDataParserMixin';
import ExcludeItemsMixin from '../../mixins/Report/ExcludeItemsMixin';
import { UPDATE_JOB_REPORT } from '../../store/jobs/actions/actionTypes';
import { REPORTS_GET_REPORT_VIEW } from '../../store/reports/actions/actionTypes';
import client from '../../config/api';

const uuidv4 = v4;

export default {
  name: 'report-template',
  mixins: [ReportDataParserMixin, ExcludeItemsMixin],
  components: {
    ReportHeader,
    Instruction,
    Diagram,
    LabelInput,
    ExternalData,
    RepairAssessmentDetail,
    RepairAssessmentDetailDialog,
    DeviationReport,
    DeviationReportModal,
  },
  data() {
    return {
      isLoading: true,
      repairAssessmentVisible: false,
      companyInfoText: '',
      customerInfoText: '',
      serialNumber: '',
      unitModel: '',
      projectInfoText: '',
      salesInfoText: '',
      salesForceNumber: '',
      summaryText: '',
      showSummaryExemple: false,
      readyToDownload: false,
      docFileUrl: '',
      filteredRepairAssessment: [],
      disabledDownload: false,
      revisionDescription: '',
      csmField: '',
      repairEngineer: '',
      activeTab: 0,
      deviationReportVisible: false,
      deviationKey: 0,
    };
  },
  computed: {
    job() {
      return this.$store.state.currentJob;
    },
    tasksInOrder() {
      return this.$store.state.ReportsModule.reportView.tasks;
    },
    excludedItems() {
      return this.$store.state.ReportsModule.excludedItems;
    },
    isInternalReport() {
      return this.$store.state.ReportsModule.isInternalReport;
    },
    filteredMinorRepairAssessment: {
      get() {
        return this.filteredRepairAssessment.filter((row) => row.isMinor);
      },
      set(value) {
        this.filteredRepairAssessment = [
          ...this.filteredRepairAssessment,
          value,
        ];
      },
    },
    filteredMajorRepairAssessment: {
      get() {
        return this.filteredRepairAssessment.filter((row) => !row.isMinor);
      },
      set(value) {
        this.filteredRepairAssessment = [
          ...this.filteredRepairAssessment,
          value,
        ];
      },
    },
  },
  methods: {
    onQuestionSummaryClick() {
      this.showSummaryExemple = !this.showSummaryExemple;
    },
    async onUpdateJobReport(field) {
      const payload = {
        _id: this.job._id,
        reportUpdate: true,
      };
      payload[field] = this[field];

      if (this.job[field] === this[field]) return;

      await this.$store.dispatch(UPDATE_JOB_REPORT, payload);
    },
    prepareExportJsonFile() {
      const data = [];
      this.tasksInOrder.forEach((task) => {
        if (task.taskType === 'diagram') {
          const taskCanvasDiagram = document.getElementById(
            `canvas-diagram-${task._id}`
          );
          if (!taskCanvasDiagram) return;
          // element.canvas = taskCanvasDiagram.toDataURL('image/jpeg');
          const canvas = taskCanvasDiagram.toDataURL();
          data.push({
            id: task._id,
            canvas,
          });
        }
      });

      return data;
    },
    getAttachments(task, allAttachmentsImagesUrls) {
      const attachments = [];

      if (task.taskType === 'diagram') {
        const parseData = this.getAttachemntsDiagram(task).filter((att) => {
          return !att.isFreeFinding;
        });
        parseData.forEach((att) => {
          const images = [];
          if (att.images) {
            att.images.forEach((img) => {
              if (!this.isImageExclude(img.img)) {
                allAttachmentsImagesUrls.push(img.img);

                const imageObj = {
                  img: img.img,
                  imgDim: img.imgDim,
                  // description: img.imgDescription ? img.imgDescription : '',
                };

                if (img.imgDescription) {
                  imageObj.description = img.imgDescription;
                }

                if (img.imgName && !img.imgName.startsWith('picture_')) {
                  imageObj.name = img.imgName;
                }
                images.push(imageObj);
              }
            });
          }
          const label = att.name;
          const attachment = {
            att_array: images,
            label: `Label ${label}`,
          };
          if (att.findingsByInspector) {
            attachment.finding = `${att.findingsByInspector}<br>`;
          }
          attachments.push(attachment);
        });
      }

      if (task.taskType === 'instruction') {
        const parsedData = this.getAttachemntsInstruction(task);
        parsedData.forEach((att) => {
          if (att.images && att.images.length > 0) {
            const attItem = {
              images: [],
            };
            attItem.question = att.question ? att.question : '';

            att.images.forEach((img) => {
              if (!this.isImageExclude(img.img)) {
                const desc = img.imgDescription ? img.imgDescription : '';
                const imageObj = {
                  img: img.img,
                  imgDim: img.imgDim,
                  imgDescription: `Description: ${desc}`,
                };

                if (img.imgName && !img.imgName.startsWith('picture_')) {
                  imageObj.imgName = img.imgName;
                }
                attItem.images.push(imageObj);
                allAttachmentsImagesUrls.push(img.img);
              }
            });
            attachments.push(attItem);
          }
        });
      }

      return attachments;
    },
    async onDownloadReport() {
      const isValid = this.$refs.refRevisionDescription.checkHtml5Validity();
      if (!isValid) {
        const element = document.getElementById('refRevisionDescription');
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        return;
      }
      this.disabledDownload = true;
      try {
        const reportId = uuidv4();
        const jobId = this.job._id;
        const canvasDiagrams = this.prepareExportJsonFile();
        let jsonFileName = null;
        if (canvasDiagrams.length > 0) {
          jsonFileName = `${reportId}_json.txt`;
          const blob = new Blob([JSON.stringify(canvasDiagrams)], {
            type: 'text/plain',
          });
          const file = new File([blob], jsonFileName);
          await this.uploadToS3(file, 'json');
        }

        const isCustomerReport = !this.isInternalReport;
        const jsonData = {
          reportId,
          jobId,
          api_endpoint: process.env.VUE_APP_API_ENDPOINT,
          revisionDescription: this.revisionDescription,
          reportDate: Date.now(),
          jsonFileName,
          isCustomerReport,
        };
        await client.post('/report/download', { jsonData });
        this.startPolling(reportId);
      } catch (error) {
        console.error(error);
        alert('Something went wrong when trying to generate report');
        this.disabledDownload = false;
      }
    },
    async uploadToS3(file, path) {
      const dijConfigurations = new DIJVueConfigurations();
      const url = await dijConfigurations.uploadFileToS3(file, file.name, path);
      return url;
    },
    async startPolling(reportId) {
      let attempts = 0;
      const maxAttempts = 30;
      const pollInterval = 4000;
      const poll = setInterval(async () => {
        attempts++;
        if (attempts >= maxAttempts) {
          clearInterval(poll);
          this.status = 'Polling stopped. Server not ready.';
          throw new Error('Error when generating file');
        }

        const serverStatus = await this.checkServerStatus(reportId, poll);

        if (serverStatus.readyToDownload) {
          this.disabledDownload = false;
          clearInterval(poll);
          this.readyToDownload = true;
          this.docFileUrl = serverStatus.path;
          this.triggerDownload(serverStatus.path);
          await this.$store.dispatch('getJob', this.job._id);
        }
      }, pollInterval);
    },
    async checkServerStatus(reportId, poll) {
      try {
        const response = await client.get(
          `/job/${this.job._id}/report/${reportId}`
        );
        return response.data;
      } catch (error) {
        console.error('Error checking server status:', error);
        this.disabledDownload = false;
        clearInterval(poll);
        this.readyToDownload = true;
        await this.$store.dispatch('getJob', this.job._id);
        alert('Something went wrong when trying to generate report');
        return 'Not Ready';
      }
    },
    triggerDownload(downloadLink) {
      const anchor = document.createElement('a');
      anchor.style.display = 'none';
      anchor.href = downloadLink;
      anchor.setAttribute('download', '');

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    },
    showRepairAssessment() {
      this.repairAssessmentVisible = true;
    },
    hideRepairAssessment() {
      this.repairAssessmentVisible = false;

      const event = new Event('ra-update');
      document.dispatchEvent(event);
    },
    addDeviationReport() {
      const updatedJob = { ...this.job };
      updatedJob.deviationReport = {
        radio: 'review_only',
        rows: [],
      };
      this.$store.dispatch('updateJob', updatedJob);
    },
    showDeviationReport() {
      this.deviationReportVisible = true;
    },
    hideDeviationReport() {
      this.deviationReportVisible = false;
      // this.$refs.deviationReport.resetRadio(
      //   this.$store.state.currentJob.deviationReport.radio
      // );
      this.deviationKey += 1;
    },
  },

  watch: {
    repairAssessmentVisible(value) {
      if (!value) {
        this.$store
          .dispatch('getRepairAssessment', this.job)
          .then((repairAssessment) => {
            this.repairAssessment = [...repairAssessment];
            this.filteredRepairAssessment = [...repairAssessment];
          });
      }
    },
  },

  async created() {
    this.isLoading = true;
    const { id } = this.$route.params;
    await this.$store.dispatch('getJob', id);
    await this.$store.dispatch(REPORTS_GET_REPORT_VIEW, id);

    this.companyInfoText = this.job.companyInfoText;
    this.customerInfoText = this.job.customerInfoText;
    if (Array.isArray(this.job.model)) {
      this.unitModel = this.job.model[0].text;
    } else {
      this.unitModel = this.job.model;
    }
    this.projectInfoText = this.job.projectInfoText;
    this.serialNumber = this.job.serialNumber;
    this.salesInfoText = this.job.salesInfoText;
    this.salesForceNumber = this.job.salesForceNumber;
    this.summaryText = this.job.summaryText;
    this.revisionDescription = this.job.revisionDescription;
    this.csmField = this.job.csmField;
    this.repairEngineer = this.job.repairEngineer;

    if (this.job.repairAssessment) {
      const repairAssessment = [];
      this.job.repairAssessment.forEach((element) => {
        if (element.id) {
          repairAssessment.push({ ...element });
        } else {
          repairAssessment.push({ id: uuidv4(), ...element });
        }
      });
      this.filteredRepairAssessment = repairAssessment;
    }
    this.isLoading = false;
  },
};
</script>

<style scoped lang="scss">
.space-ra {
  height: 50px;
}

#container-main {
  padding: 30px 10px;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 105px);
}

#lable-summary {
  display: flex;
  align-items: center;
}

#icon-summary {
  margin-left: 5px;
}

#revisionContainer {
  width: 50%;
}

#executive-summary-example {
  max-width: 600px;
  margin: 20px;
}

.repair-assessment-container {
  background-color: #fff;
  margin-top: 20px;

  div:nth-of-type(1) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;

    div:nth-of-type(1) {
      display: flex;
      align-items: center;

      span:nth-of-type(1) {
        margin-right: 10px;
      }
    }
  }
}

.deviation-report-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  background-color: #fff;
}

.deviation-report-edit {
  display: flex;

  margin-left: 20px;
}
</style>
