<template>
  <div class="diagram-container">
    <DiagramBuilder
      ref="diagramBuilder"
      v-if="currentTask"
      :taskProp="currentTask"
      :show-toolbar="showToolbar"
      :show-list="showList"
      :tool="tool"
    />
  </div>
</template>

<script>
import DiagramBuilder from '../DiagramBuilder/DiagramBuilder';
import BubbleDTO from '../../shared/dtos/BubbleDTO';

import { SAVE_BUBBLES } from '../../store/bubbles/actions/actionTypes';
import { GET_TASK_BUBBLES } from '../../store/bubbles/getters/getterTypes';
import { GET_CURRENT_TASK } from '../../store/tasks/getters/getterTypes';

export default {
  components: { DiagramBuilder },
  props: {
    showToolbar: {
      type: Boolean,
      required: true,
    },
    showList: {
      type: Boolean,
      required: true,
    },
    tool: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      currentTask: null,
    };
  },
  computed: {
    task() {
      return this.$store.getters[GET_CURRENT_TASK];
    },
    taskBubbles() {
      return this.$store.getters[GET_TASK_BUBBLES];
    },
  },
  mounted() {
    // Reason why copying the task is that once send
    // to Diagram builder we need to set all the information beforehand
    this.currentTask = this.task;
    this.currentTask.diagram = this.task.diagram || {};
    this.currentTask.diagram.annotations = [];
    this.currentTask.diagram.inspections = [];

    if (this.taskBubbles) {
      this.taskBubbles.forEach((bubble) => {
        if (['text', 'arrow', 'line'].includes(bubble.type)) {
          this.currentTask.diagram.annotations.push(bubble);
        } else {
          this.currentTask.diagram.inspections.push(bubble);
        }
      });
    }
  },
  methods: {
    onAccuScanImport(data) {
      this.$refs.diagramBuilder.importAccuScanValues(data);
    },
    getInspections() {
      return this.$refs.diagramBuilder.inspections || [];
    },
    async onSave() {
      const bubbles = this.taskBubbles.map(
        (inspection) =>
          new BubbleDTO({
            _id: inspection.id,
            ...inspection,
          })
      );

      if (bubbles.length > 0) {
        await this.$store.dispatch(SAVE_BUBBLES, {
          taskId: this.currentTask._id,
          data: bubbles,
        });
      }
    },
  },
};
</script>

<style scoped>
.diagram-container {
  height: calc(88vh + 22px);
}
</style>
